import { postApiWrapper } from './token-wrapper-function';
import { loggedInNodeId } from './authAction';

export const editMobile = async (data) => {
  const url = `/api/users/basic/mobile/edit`;
  const postBody = {
    ...data,
    logged_in_node_id: loggedInNodeId,
    device_info: {
      appVersion: 'WEB',
    }
  };
  const result = await postApiWrapper(url, postBody);
  return result;
  // if (Token) {
  //   try {
  //     const res = await fetch(`/api/users/basic/mobile/edit`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Token ' + Token,
  //       },
  //       body: JSON.stringify({
  //         ...data,
  //         logged_in_node_id: loggedInNodeId,
  //         device_info: {
  //           appVersion: 'WEB',
  //         },
  //       }),
  //     });
  //     const res_json = await res.json();
  //     return res_json;
  //   } catch (e) {
  //     console.log('Request Failed', e);
  //   }
  // }
};

export const unlinkRegisteredMobile = async (data) => {
  const url = `/api/unlink_by_mobile`;
  const result = await postApiWrapper(url, data);
  return result;
}
