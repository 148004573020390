import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { editMobile } from '../../actions/mobileAction';
import MobileComponent from './mobile-component';
import { unlinkRegisteredMobile } from '../../actions/mobileAction';

const MobileDetails = ({
  moreDetails = {},
  selectedNode = [],
  getNodeDetails,
  setLoading,
}) => {

  const [basicProfile, setBasicProfile] = useState(moreDetails?.basicProfile);
  const [primaryMobile, setPrimaryMobile] = useState(basicProfile?.primary_mobile);
  const [secondaryMobile, setSecondaryMobile] = useState(basicProfile?.secondry_mobile);
  const [whatsappMobile, setWhatsappMobile] = useState(basicProfile?.whatsapp_mobile);
  const [registeredMobile, setRegisteredMobile] = useState(basicProfile?.registered_mobile);
  const [isEditable, setEditable] = useState(false);

  const handleSubmit = async () => {
    const newMobileData = {
      primary_mobile: primaryMobile,
      secondry_mobile: secondaryMobile,
      whatsapp_mobile: whatsappMobile,
      graph_node_id: selectedNode.uid,
    };

    setLoading(true);
    try {
      const res = await editMobile(newMobileData);
      await getNodeDetails();
      setLoading(false);
      if (res.status == false)
        toast.error(`Could not update mobile\n Error: ${res.message}`);
      else
        toast.success('Updated mobile successfully');
    } catch (e) {
      console.log('Could not update mobile', e);
      await getNodeDetails();
      setLoading(false);
      toast.error(`Could not update mobile\n Error: ${e}`);
    }
    setEditable(false);
  };

  useEffect(() => {
    setBasicProfile(moreDetails?.basicProfile);
  }, [moreDetails])

  useEffect(() => {
    setPrimaryMobile(basicProfile?.primary_mobile);
    setSecondaryMobile(basicProfile?.secondry_mobile);
    setWhatsappMobile(basicProfile?.whatsapp_mobile);
  }, [basicProfile])

  const unlinkRegMob = async () => {
    let sentData = {
      mobile: basicProfile?.registered_mobile,
      graph_node_id: selectedNode.uid,
    };
    const response = await unlinkRegisteredMobile(sentData);
    if (response?.status) {
      setRegisteredMobile('');
      toast.success(`${response?.message}`);
    }
    else {
      toast.error(`${response?.message}`);
    }
  }

  return (
    <div>
      <h2>Mobile Info</h2>
      <button onClick={() => setEditable(true)}>Edit Mobile</button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <MobileComponent label="Primary Mobile" mobile={primaryMobile} setMobile={setPrimaryMobile} isEditable={isEditable} />
        <MobileComponent label="Secondary Mobile" mobile={secondaryMobile} setMobile={setSecondaryMobile} isEditable={isEditable} />
        <MobileComponent label="WhatsApp Mobile" mobile={whatsappMobile} setMobile={setWhatsappMobile} isEditable={isEditable} />
        {isEditable && <button type="submit">Submit</button>}
        <br />
        <label>
          Registered No:
          <input value={registeredMobile} disabled placeholder="Enter Registered Mobile" />
        </label>
        &nbsp;
      </form>
      {basicProfile?.registered_mobile ? (<button onClick={unlinkRegMob}>Unlink Registered Mobile</button>) : ""}
    </div>
  );
};

export default MobileDetails;